<template>
  <vs-dropdown vs-trigger-click class="cursor-pointer">
    <feather-icon icon="MoreVerticalIcon" svgClasses="h-4 w-4" class="mr-1" />
    <vs-dropdown-menu>
      <!-- <vs-dropdown-item @click="$emit('edit', item)">
        {{ lang.surveyCharts.main.metric.actions.edit[languageSelected] }}
      </vs-dropdown-item> -->
      <vs-dropdown-item @click="$emit('copy', item)">
        {{ lang.surveyCharts.main.metric.actions.copy[languageSelected] }}
      </vs-dropdown-item>
      <vs-dropdown-item @click="$emit('delete', item)">
        {{ lang.surveyCharts.main.metric.actions.delete[languageSelected] }}
      </vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChartDropdown',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>

<style lang="scss">
.vs-dropdown--menu--after {
  right: 0px !important;
}
</style>
